import Link from 'next/link'
import { useRouter } from "next/router";

import Icon from '@mdi/react';
import { mdiCashCheck, mdiLock, mdiCart, mdiAlertCircle, mdiHome, mdiBookOpenVariant, mdiMapSearch, mdiCog, mdiAccount, mdiRocketLaunch, mdiBrush, mdiWeb, mdiDesktopClassic } from '@mdi/js';

const navigation = [
  {
    subheader: "My Restaurant",
    items: [
      {
        label: "Overview",
        slug: "/",
        icon: mdiHome
      },
      {
        label: "Edit Menu",
        slug: "/edit-menu",
        icon: mdiBookOpenVariant
      },
      {
        label: "Location & Hours",
        slug: "/locations",
        icon: mdiMapSearch
      },
      {
        label: "View Live Site",
        type: "viewPublish",
        icon: mdiWeb
      }
    ]
  },
  {
    subheader: "Ordering",
    items: [
      {
        label: "Online Ordering",
        slug: "/online-order",
        icon: mdiCart
      },
      {
        label: "Terminal",
        slug: "/terminal",
        icon: mdiDesktopClassic,
        target: "_blank"
      },
    ]
  },
  {
    subheader: "Website Content",
    hide: true,
    items: [
      {
        label: "About",
        slug: "/page-editor/about",
        hide: !process.env.DEV_MODE
      },
      {
        label: "Gallery",
        slug: "/page-editor/gallery",
        planFeature: "hasGalleryPage"
      },
      {
        label: "Reviews",
        slug: "/page-editor/reviews",
        hide: !process.env.DEV_MODE
      },
      {
        label: "Team",
        slug: "/page-editor/team",
        hide: !process.env.DEV_MODE
      },
      {
        label: "Events",
        slug: "/page-editor/events",
        hide: !process.env.DEV_MODE
      }
    ]
  },
  {
    subheader: "Configuration",
    items: [
      {
        label: "Business Info",
        slug: "/business-info",
        icon: mdiCog
      },
      {
        label: "Site Design",
        slug: "/design",
        icon: mdiBrush,
        hide: process.env.NODE_ENV !== "development"
      },
      {
        label: "Payments",
        slug: "/payments",
        icon: mdiCashCheck,
      },
      {
        label: "Plans",
        slug: "/plans",
        icon: mdiRocketLaunch,
        hide: process.env.NODE_ENV !== "development"
      },
      {
        label: "Account Settings",
        slug: "/account",
        icon: mdiAccount
      },
      {
        label: "Internal",
        slug: "/internal",
        hide: process.env.API_PROVIDER !== "local"
      }
    ]
  }
];

import {
  useContext,
  useEffect,
  useState
} from "react";

import SiteContext from 'context/SiteContext';

import getPlanFeatureValue from "plan/PlanService";
import AppLogo from './AppLogo';

export default function Drawer({
  show=true,
  onClickOverlay
}) {
  const router = useRouter();
  const { site } = useContext(SiteContext);

  return (
    <div>
      <aside 
        className="fixed z-20 inset-0 w-60 text-white pb-10 overflow-y-auto transition duration-300" 
        style={{ 
          background: '#1b1e27',
          transform: show ? "" : 'translateX(-100%)'
        }}>
        <div className="px-4 mt-4 flex items-center font-bold text-sm mb-8">
          <AppLogo 
            className="mr-2 w-6"
            style={{
              fill: "#FFF"
            }} />
            OpenTummy
        </div>
        
        <nav>
          {
            navigation.map((nav, navIndex) => {
              if(nav.hide) {
                return null;
              }

              return (
                  <div key={ navIndex }>
                    <div className="text-xs uppercase font-600 px-4 py-1 text-slate-500 mt-4">
                      { nav.subheader }
                    </div>
                    <ul>
                      {
                        nav.items.map((item, itemIndex) => {
                          if(item.hide) {
                            return null;
                          }

                          let isActive = false;
                          if(item?.slug) {
                            isActive = router.asPath.startsWith(item.slug);
                            if(item.slug === "/") {
                              isActive = router.asPath === item.slug;
                            }
                          } else if(item.type == "viewPublish") {
                            let slug = item.type === "viewOnlineOrder" ? "" : "";

                            if(process.env.NODE_ENV === "development") {
                              item.slug = `http://localhost/${ site.free_domain }`;
                            } else {
                              item.slug = `https://${ site.free_domain }.opentummy.ca`;
                            }
                          } else if(item.type === "viewOnlineOrder") {
                            item.slug = "/order-settings";
                          }

                          let isLocked = false;
                          if(item.planFeature) {
                            isLocked = !getPlanFeatureValue(site.plan_id, item.planFeature);
                          }

                          let isCompleted = item.isCompleted ?? true;

                          return (
                            <li 
                              key={ itemIndex } 
                              className={ `${isActive ? 'bg-primary' : ''}  hover:bg-primary transition-colors` }>
                              <Link 
                                href={ item.slug || "" }
                                target={ item?.target ?? item?.slug?.includes("http") ? "_blank" : "" }
                                onClick={ () => onClickOverlay }>
                                <div className="px-4 py-2 flex items-center">
                                  {
                                    item.icon &&
                                    <Icon path={ item.icon } size={0.75 } className="mr-2" />
                                  }
                                  { item.label }
                                  {
                                    isLocked && 
                                    <Icon path={ mdiLock } size={ 0.75 } className="ml-2" />
                                  }
                                  {
                                    !isCompleted &&
                                    <Icon path={ mdiAlertCircle } size={ 0.75 } className="ml-2" color="orange" />
                                  }
                                </div>
                              </Link>
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
              )
            })
          }
        </nav>
      </aside>
      {
        show &&
        <div className="fixed z-10 inset-0 md:hidden" onClick={ onClickOverlay }>
        </div>
      }
    </div>
  )
}