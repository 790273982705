import Tooltip from "honeyjar/Tooltip";

export default function IconButton({
    children,
    className="",
    ...props
}) {
    return (
        <Tooltip title={ props.title }>
            <button
                type="button"
                { ...props }
                className={ `text-slate-500 transition-colors disabled:cursor-not-allowed disabled:opacity-60 enabled:hover:bg-slate-200 flex items-center justify-center transition-colors p-2 rounded-full items-center min-w-[24px] min-h-[24px] ${ className }` }>
                { children }
            </button>
        </Tooltip>
    )
} 