import Button from "honeyjar/Button";
import Icon from '@mdi/react';
import { mdiContentCopy, mdiEye, mdiDelete  } from '@mdi/js';

export default function AppToolbar(props) {
  let {
    handleSubmitForm,
    warningOnExit,
    labels
  } = props;

  return (
    <div>
      {
        (props.onDelete || props.hasDuplicate) &&
        <div className="flex gap-2 mb-4">
          {
            props.hasDuplicate &&
            <Button
              disabled={ warningOnExit }
              variant="link"
              size="small"
              className="flex items-center"
              onClick={ () => {
              handleSubmitForm({ isDuplicate: true });
            } }>
              <Icon path={mdiContentCopy} size={ 0.6 } className="mr-1" />
              Duplicate { labels.itemName }
            </Button>
          }
          {
            props.viewURL &&
            <Button
              disabled={ warningOnExit }
              variant="link"
              size="small"
              className="flex items-center"
              onClick={ () => {
                window.open(props.viewURL)
              } }>
                 <Icon path={mdiEye} size={ 0.6 } className="mr-1" />
                  View
            </Button>
          }
          {
            props.onDelete &&
            <Button 
              color="danger"
              variant="link"
              size="small"
              className="flex items-center"
              onClick={ props.onDelete }>
                <Icon path={mdiDelete} size={ 0.6 } className="mr-1" />
                Delete { labels.itemName }
            </Button>
          }
        </div>
      }
    </div>
  )

}