import Router, { useRouter } from "next/router"
import { useRef, useEffect, useCallback } from "react"

const throwFakeErrorToFoolNextRouter = () => {
    // Throwing an actual error class trips the Next.JS 500 Page, this string literal does not.
    throw "Abort route change due to unsaved changes in form. Triggered by useWarningOnExit. Please ignore this error. See issue https://github.com/vercel/next.js/issues/2476"
}

/** @returns {Window?} */
const getWindow = () => typeof window !== "undefined" && window

/** @returns {History.state?} */
const getHistory = () => getWindow()?.history?.state

/**
 * @param {boolean} shouldWarn 
 * @param {string?} message 
 */
export const useWarningOnExit = (shouldWarn = true, message = "Discard unsaved changes?") => {
    const router = useRouter()
    const lastHistory = useRef(getHistory())

    useEffect(() => {
        const storeLastHistoryState = () => {
            lastHistory.current = getHistory()
        }
        router.events.on("routeChangeComplete", storeLastHistoryState)
        return () => {
            router.events.off("routeChangeComplete", storeLastHistoryState)
        }
    }, [router])

    /**
     * @experimental HACK - idx is not documented
     * Determines which direction to travel in history.
     */
    const revertTheChangeRouterJustMade = useCallback(() => {
        const state = lastHistory.current
        if (
            state !== null &&
            history.state !== null &&
            state.idx !== history.state.idx
        ) {
            const delta = lastHistory.current.idx < history.state.idx ? -1 : 1
            history.go(delta)
        }
    }, [])

    const killRouterEvent = useCallback(() => {
        router.events.emit("routeChangeError")
        revertTheChangeRouterJustMade()
        throwFakeErrorToFoolNextRouter()
    }, [revertTheChangeRouterJustMade, router])

    useEffect(() => {
        let isWarned = false

        const routeChangeStart = (url) => {
            if (router.asPath !== url && shouldWarn && !isWarned) {
                isWarned = true
                if (window.confirm(message)) {
                    router.push(url)
                    return
                }
                isWarned = false
                killRouterEvent()
            }
        }

        const beforeUnload = (e) => {
            if (shouldWarn && !isWarned) {
                // const event = e ?? getWindow()?.event
                // event?.returnValue = message
                return message
            }
            return null
        }

        router.events.on("routeChangeStart", routeChangeStart)
        getWindow()?.addEventListener("beforeunload", beforeUnload)

        return () => {
            router.events.off("routeChangeStart", routeChangeStart)
            getWindow()?.removeEventListener("beforeunload", beforeUnload)
        }
    }, [message, shouldWarn, killRouterEvent, router])
}

export default useWarningOnExit