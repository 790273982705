
import StatusCard from "honeyjar/StatusCard";

export default function ErrorsCard(props) {
  let {
    title="Ooops.",
    onClose
  } = props;

  return (
    <StatusCard
      title={ title }
      onClose={ onClose }
      className="bg-red-100 border-red-500">
        { props.children }
    </StatusCard>
  )
}