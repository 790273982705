import {
  useState,
  useEffect
} from "react";

import IconButton from "honeyjar/IconButton";
import AppButtons from "components/core/AppButtons";
import { mdiMenu } from '@mdi/js';
import AppBarBackButton from "./AppBarBackButton";
import Icon from '@mdi/react';

function AppBarMobileMenuButton(props) {
  return (
    <IconButton className="mr-2 enabled:hover:bg-white lg:hidden" onClick={ props.onClick }>
      <Icon path={mdiMenu} size={1} />
    </IconButton>
  )
}

export default function AppBar(props) {
  let {
    title,
    back, 
    onSave,
    handleClickDrawer
  } = props;

  if(onSave) {
    return (
      <div 
        className="sticky top-0 w-full z-50" style={{ backgroundColor: '#f0f4f7' }}>
        <div className={ `flex w-full transition-all py-5 justify-between items-center` }>
          <div className="flex items-center">
              {
                back ?
                <AppBarBackButton
                  back={ back } />
                :
                <AppBarMobileMenuButton
                  onClick={ handleClickDrawer } />
              } 
            <div className="text-xl font-bold truncate max-w-[400px]">
              { title }
            </div>
          </div>
          <AppButtons
            { ...props } />
        </div>
      </div>
    )
  }

  return (
    <div className="flex items-center mt-16 mb-2">
      {
        back ?
        <AppBarBackButton
          back={ back } />
        :
        <AppBarMobileMenuButton
          onClick={ handleClickDrawer } />
      } 
      <div className="text-3xl font-bold text-slate-500">
        { props.title }
      </div>
    </div>
  )
}