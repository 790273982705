import IconButton from "honeyjar/IconButton";

import Icon from '@mdi/react';
import { mdiPlus, mdiClose  } from '@mdi/js';

export default function StatusCard(props) {
    return (
        <div className={ `mb-8 rounded border-t-4 ${props.className || "bg-white"}` }>
            <div className="px-8 py-6">
                <div className="flex items-center justify-between h-[30px]">
                    <div className="font-bold text-lg">
                        { props.title }
                    </div>
                    {
                        props.onClose &&
                        <IconButton onClick={ props.onClose }>
                            <Icon path={mdiClose} size={1} />
                        </IconButton>
                    }
                    
                </div>
                <div>
                    { props.children }
                </div>
            </div>
        </div>
    )
}