import {
    PLAN_FEATURES,
    PLAN_FEATURE_VALUES
} from "plan/PLANS";

export default function getPlanFeatureValue(planId=1, featureName) {
    if(PLAN_FEATURE_VALUES[featureName]) {
        if(PLAN_FEATURE_VALUES[featureName][planId]) {
            return PLAN_FEATURE_VALUES[featureName][planId];
        } else if(PLAN_FEATURES[featureName]) {
            return PLAN_FEATURES[featureName];
        }
    } else if(PLAN_FEATURES[featureName]) {
        return PLAN_FEATURES[featureName];
    }

    return false;
}