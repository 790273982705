import {
  useState,
  useEffect,
  useCallback
} from "react";

import Router from 'next/router'
import Head from 'next/head'

import AppBar from "components/core/AppBar";
import AppLoading from "components/core/AppLoading";
import Drawer from "components/core/Drawer";
import AppButtons from "components/core/AppButtons";
import ErrorsCard from "components/core/ErrorsCard";

import Button  from "honeyjar/Button"
import useWarningOnExit from "hooks/useWarningOnExit";
import StatusCard from "honeyjar/StatusCard";
import LoadingIcon from "icons/LoadingIcon.svg";
import AppToolbar from "./AppToolbar";
import { useWindowDimensions } from "effects/useWindowDimensions";

interface DashboardInterface {
  onValidate?:any,
  onSave?:any,
  warningOnExit?:boolean
  title?:string,
  loading?:boolean,
  back?:string,
  hasDuplicate?:boolean,
  children?:any
}

export default function Dashboard(props: DashboardInterface) {
  let {
    onValidate,
    onSave,
    warningOnExit=false,
    loading,
    title
  } = props;
  
  useWarningOnExit(warningOnExit)

  const [ pageTitle, setPageTitle ] = useState(`${ process.env.APP_NAME } Dashboard`)
  useEffect(() => {
    setPageTitle(
      `${ props.title ? `${props.title} — ` : "" } ${ process.env.APP_NAME } Dashboard`
    )
  }, [
    title
  ]);


  const [ savingStatus, setSavingStatus ] = useState("");
  const [ formErrors, setFormErrors ] = useState([]);

  // refractor
  const handleSubmitForm = useCallback(async (event) => {
    try {
      let payload;
      let isValid = true;

      setFormErrors([]);

      if(onValidate) {
        let validation = await onValidate();

        if(validation?.errors?.length) {
          isValid = false
          setFormErrors(validation.errors);
          window.scrollTo(0,0);
        } else if(validation?.payload) {
          payload = validation.payload;
        }
      }

      if(isValid) {
        setSavingStatus("saving");
        let response = await onSave(payload, event?.isDuplicate);

        if(response?.redirect) {
          setTimeout(() => {
            Router.push(response?.redirect);
            setSavingStatus("");
          }, 100)
        } else {
          setSavingStatus("saved");
          window.scrollTo(0,0);
        }
      }
    } catch(error) {
      setSavingStatus("error");
      window.scrollTo(0,0);

      if(process.env.NODE_ENV === "development") {
        throw new Error(error)
      }
    }
  }, [ 
    onValidate,
    onSave
  ]);

  const closeStatusCard = useCallback(() => {
    setSavingStatus("")
  }, [])

  const {
      windowWidth
  } = useWindowDimensions();

  const [ showDrawer, setShowDrawer ] = useState(true);
  useEffect(() => {
    if(windowWidth > 1018) {
      setShowDrawer(true);
    } else {
      setShowDrawer(false);
    }
  }, [
    windowWidth
  ])
 
  const handleClickDrawer = useCallback(() => {
    setShowDrawer(!showDrawer)
  }, [
    showDrawer
  ])

  return (
    <div>
      <Head>
        <title>{ pageTitle }</title>
      </Head>
      <div>
        <Drawer 
          show={ showDrawer }
          onClickOverlay={ () => setShowDrawer(false) } />
        
        <div className="lg:pl-[15rem]">
          <div className="mx-4 lg:ml-16 lg:mx-16 xl:mr-[32rem]">

            {
              title && !loading &&
              <AppBar 
                { ...props }
                handleSubmitForm={ handleSubmitForm }
                handleClickDrawer={ handleClickDrawer } />
            }

            <div className="py-10">

              {
                loading
                ?
                <div className="mt-32">
                  <LoadingIcon />
                </div>
                :
                <>
                  {
                    (props.hasDuplicate) && 
                    <AppToolbar
                      {...props}
                      handleSubmitForm={ handleSubmitForm } />
                  }
                  

                  {
                    savingStatus === "error" &&
                    <ErrorsCard onClose={ closeStatusCard }>
                      We have detected an error while saving your content. Please try again.
                    </ErrorsCard>
                  }

                  {
                    formErrors.length !== 0 &&
                    <ErrorsCard
                      title={ `To save your content, ${ formErrors.length } change${ formErrors.length === 1 ? '' : 's' } need${ formErrors.length === 1 ? 's' : '' } to be made` }
                      onClose={ () => {
                        setFormErrors([]);
                        closeStatusCard();
                      } }>
                      <ul className="pl-3">
                          {
                            formErrors.map((error, index) => {
                              return (
                                <li key={index}  className="list-disc">
                                  { error.message }
                                </li>
                              )
                            })
                          }
                      </ul>
                    </ErrorsCard>
                  }

                  {
                    savingStatus === "saving" &&
                    <StatusCard
                      title="Saving In Progress"
                      className="bg-white border-blue-500">
                        Your changes are being saved...
                    </StatusCard>
                  }

                  {
                    savingStatus === "saved" &&
                    <StatusCard
                      title="Saved"
                      onClose={ closeStatusCard }
                      className="bg-green-100 border-green-500">
                        Your changes has been saved successful.
                    </StatusCard>
                  }

                  {
                    props.children
                  }

                  {
                    (props.onDelete || props.hasDuplicate) && false &&
                    <Card title="Actions">
                      <div className="flex gap-4">
                        {
                          props.hasDuplicate &&
                          <Button
                            disabled={ warningOnExit }
                            onClick={ () => {
                            handleSubmitForm({ isDuplicate: true });
                          } }>
                            Duplicate
                          </Button>
                        }
                        {
                          props.onDelete &&
                          <Button color="danger" onClick={ props.onDelete }>
                            Delete
                          </Button>
                        }
                      </div>
                        
                    </Card>
                  }

                  <AppButtons
                    { ...props }
                    handleSubmitForm={ handleSubmitForm }
                    disableActions={ true } />
                </>
              }

            </div>
          </div>
        </div>

        {
          savingStatus === "saving" &&
          <AppLoading />
        }
      </div>
    </div>
  )
}

/*

  return (
    <>
      <Head>
        <title>{props.title ? `${props.title} - ` : ''} { process.env.APP_NAME } Dashboard</title>
      </Head>
      <Drawer />
      <Box sx={{ ml: "240px" }}>
        <Content
            { ...props } />
      </Box>
    </>
  )
*/