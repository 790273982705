import {
  useState,
  useEffect
} from "react";

import IconButton from "honeyjar/IconButton";
import Link from "next/link";
import Icon from '@mdi/react';
import { mdiArrowLeft } from '@mdi/js';

export interface IAppBarBackButtonProps {
  back: string
}

export default function AppBarBackButton(props: IAppBarBackButtonProps) {
  let {
    back
  } = props;

  return (
      <Link href={ back }>
        <IconButton title="Go back" className="mr-2 enabled:hover:bg-white">
          <Icon path={mdiArrowLeft} size={1} />
        </IconButton>
      </Link>
  )
}