import {
  useState,
  useEffect
} from "react";

import throttle from "utils/throttle";

export function useWindowDimensions() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [ windowHeight, setWindowHeight ] = useState(window.innerHeight)

  useEffect(() => {
      const handleResize = throttle(() => {
        requestAnimationFrame(() => {
          setWindowWidth(window.innerWidth);
          setWindowHeight(window.innerHeight);
        });
      }, 200);

      window.addEventListener('resize', handleResize, { passive: true });
      handleResize();

      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  return {
    windowWidth,
    windowHeight
  }
}