export const PLANS = [
  {
      id: 1,
      name: "Presence",
      price: 0,
      priceMonthly: 0,
      desc: "The best choice for showcasing your online menu and restaurant location.",
      features: [
          "Restaurant Website",
          "Online Menu",
          "Delivery Providers",
          "Google & Bing SEO"
      ]
  },
  {
      id: 2,
      name: "Business",
      price: 10,
      priceMonthly: 13,
      popular: true,
      desc: "The ideal solution for your restaurant's online ordering needs and analytics.",
      onTop: true,
      features: [
          "Restaurant Website",
          "Online Menu",
          "Delivery Providers",
          "Google & Bing SEO",
          "Online Ordering",
          "POS Terminal",
          "Google Analytics"
      ],
      stripe: {
        "yearly": {
            "development": "test_9AQ7tb2xmcJD2kw9AD",
            "production": "test_9AQ7tb2xmcJD2kw9AD"
        },
        "monthly": {
            "development": "test_28obJrgocfVP0cofZ2",
            "production": "test_28obJrgocfVP0cofZ2"
        }
      },
  },
  {
      id: 3,
      name: "Business",
      disabled: true,
      price: 15,
      priceMonthly: 18,
      desc: "The ultimate solution for restaurants with reservations and table ordering.",
      onTop: true,
      features: [
          "Restaurant Website",
          "Online Menu",
          "Show Delivery Providers",
          "Google & Bing SEO",
          "POS Terminal",
          "Online Ordering",
          "Image Gallery",
          "Multiple Locations",
          "Google Analytics",
          "Table Management",
          "Reservation"
      ]
  }
]

export const PLAN_FEATURES = {
    "numOfLocations": 1,
    "numOfDishes": 100,
    "hasCustomDomain": false,
    "hasSiteImgLogo": false,
    "hasOnlineOrdering": false,
    "hasDishImages": false,
    "hasGalleryPage": false,
    "customizeTheme": false,
    "hasOnlineOrder": false
}

export const PLAN_FEATURE_VALUES = {
  "hasOnlineOrder": {
      2: true,
      3: true
  },
  "numOfLocations": {
      3: 3
  },
  "numOfDishes": {
      2: 1000,
      3: 1000
  },
  "hasCustomDomain": {
      2: true,
      3: true
  },
  "hasSiteImgLogo": {
      2: true,
      3: true
  },
  "hasOnlineOrdering": {
      2: true,
      3: true
  },
  "hasDishImages": {
      2: true,
      3: true
  },
  "hasGalleryPage": {
      2: true,
      3: true
  },
  "customizeTheme": {
      2: true,
      3: true
  }
}