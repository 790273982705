import {
  useState,
  useEffect
} from "react";

import Button from "honeyjar/Button";
import capitalizeFirstLetter from "utils/capitalizeFirstLetter";

let actionButtonClass = "px-6 py-4 disabled:opacity-40 enabled:hover:bg-slate-200 w-full text-left disabled:cursor-not-allowed"

function ActionsButton(props) {
  let {
    onDelete,
    handleSubmitForm,
    warningOnExit,
    hasDuplicate
  } = props;

  const [ showDropdown, setShowDropdown ] = useState(false);

  useEffect(() => {
    const hideDropdown = (event) => {
      if(!event?.target?.closest(".dropdown")) {
        setShowDropdown(false);
      }
    }

    if(showDropdown) {
      addEventListener("mousedown", hideDropdown);
    } else {
      removeEventListener("mousedown", hideDropdown)
    }

    return () => {
      removeEventListener("mousedown", hideDropdown)
    }
  }, [ showDropdown ])

  return (
    <div className="relative">
      <div>
        <Button
            variant="outlined"
            onClick={ () => setShowDropdown(!showDropdown) }>
            Actions
        </Button>
      </div>
      {
        showDropdown &&
        <div className="dropdown absolute right-0 pt-2">
          <div className="bg-white min-w-[180px] drop-shadow-lg">
            {
              hasDuplicate &&
              <button 
                disabled={ warningOnExit }
                className={ actionButtonClass }
                onClick={ () => {
                  handleSubmitForm({ isDuplicate: true });
                  setShowDropdown(false);
                } }>
                Duplicate
              </button>
            }
            {
              onDelete &&
              <button 
                onClick={ () => {
                  onDelete();
                  setShowDropdown(false);
                } }
                className={ `${ actionButtonClass } text-red-600` }>
                Delete
              </button>
            }
            
          </div>
        </div>
      }
    </div>
  )
}

export default function AppButtons(props) {
  let {
    onSave,
    handleSubmitForm,
    warningOnExit,
    hasDuplicate,
    disableActions=false,
    onDelete,
    labels,
    saveLabel="Save Changes"
  } = props;

  return (
    <div className="flex justify-between gap-2">
      <div>
        {
          disableActions && onDelete &&
          <Button
            onClick={ () => { onDelete(); } }
            color="danger">
            Delete { capitalizeFirstLetter(labels.itemName || "") }
          </Button>
        }
      </div>
      {
        onSave &&
        <Button
          disabled={ !warningOnExit }
          onClick={ handleSubmitForm }>
          { saveLabel }
        </Button>
      }
    </div>
  )
}

/*

      {
        (!disableActions && (hasDuplicate || onDelete)) && false &&
        <ActionsButton 
          { ...props } />
      }
      
      {
        hasDuplicate && false &&
        <Button
          disabled={ warningOnExit }
          variant="outlined"
          onClick={ () => {
            handleSubmitForm({ isDuplicate: true });
          } }>
          Duplicate
        </Button>
      }
*/